import {
  AimOutlined,
  ApartmentOutlined,
  CalendarOutlined,
  DatabaseFilled,
  FieldTimeOutlined,
  FileExclamationOutlined,
  PicCenterOutlined,
  RadiusSettingOutlined,
  SettingOutlined,
  TikTokOutlined
} from '@ant-design/icons';
import { PERMISSIONS } from 'constants/permission';
import { ROUTE_URL } from 'constants/route';
import { NavItemType } from 'types/menu';

const systemManagement: NavItemType = {
  id: 'system',
  title: 'Hệ thống',
  type: 'collapse',
  icon: SettingOutlined,
  children: [
    {
      id: 'jira-time-log',
      title: 'Jira Time log',
      type: 'item',
      icon: CalendarOutlined,
      url: ROUTE_URL.system.jiraTimelog.dashboard,
      permission: PERMISSIONS.jiraTimelog
    },
    {
      id: 'location',
      title: 'Chi nhánh',
      type: 'item',
      icon: AimOutlined,
      url: ROUTE_URL.system.location,
      permission: PERMISSIONS.location
    },
    {
      id: 'role',
      title: 'Vai trò',
      type: 'item',
      icon: RadiusSettingOutlined,
      url: ROUTE_URL.system.role.list,
      permission: PERMISSIONS.role
    },
    {
      id: 'resource',
      title: 'Resource',
      type: 'item',
      icon: PicCenterOutlined,
      url: ROUTE_URL.system.resource,
      permission: PERMISSIONS.resource
    },
    {
      id: 'service',
      title: 'Service',
      type: 'item',
      icon: ApartmentOutlined,
      url: ROUTE_URL.system.service,
      permission: PERMISSIONS.service
    },
    {
      id: 'tiktok-auth',
      title: 'Tiktok Auth',
      type: 'item',
      icon: TikTokOutlined,
      url: ROUTE_URL.system.tiktokAuth,
      permission: PERMISSIONS.tiktokAuth
    },
    {
      id: 'crawl-data',
      title: 'Crawl Data',
      type: 'item',
      icon: DatabaseFilled,
      url: ROUTE_URL.system.crawlData,
      permission: PERMISSIONS.crawlData
    },
    // {
    //   id: 'cron-job',
    //   title: 'Fetch Scheduler',
    //   type: 'item',
    //   icon: FieldTimeOutlined,
    //   url: ROUTE_URL.system.fetchScheduler,
    //   permission: PERMISSIONS.cronJob
    // },
    {
      id: 'cron-job',
      title: 'Lịch Chạy Tự Động',
      type: 'collapse',
      icon: FieldTimeOutlined,
      children: [
        {
          id: 'running-cron-job',
          title: 'Job Đang Chạy',
          type: 'item',
          url: ROUTE_URL.system.cronJob.running,
          permission: PERMISSIONS.cronJob
        },
        {
          id: 'jira-cron-job',
          title: 'Lịch Chạy Jira Issue',
          type: 'item',
          url: ROUTE_URL.system.cronJob.jira,
          permission: PERMISSIONS.jiraIssueCronJob
        }
      ]
    },
    {
      id: 'error-logger',
      title: 'Danh sách lỗi',
      icon: FileExclamationOutlined,
      type: 'item',
      url: ROUTE_URL.system.errorLogger,
      permission: PERMISSIONS.systemErrorLogger
    }
  ]
};

export default systemManagement;
