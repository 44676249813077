import Loadable from 'components/Loadable';
import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router';
const ShortLinkPage = Loadable(lazy(() => import('modules/short-link/components/List')));
const AutoAddFriend = Loadable(lazy(() => import('modules/auto-add-friend/components/List')));
const ProductList = Loadable(lazy(() => import('modules/product-management/components/ProductList')));
const FBChatbotResponseManagement = Loadable(lazy(() => import('modules/fb-chatbot-response/components/FBChatbotResponseManagement')));
const BlogMenuGenerator = Loadable(lazy(() => import('modules/marketing-tools/components/BlogMenuGenerator')));
const ZaloVoucherList = Loadable(lazy(() => import('modules/zalo-mini-app/components/promotions/PromotionList')));
const ZaloUIManagement = Loadable(lazy(() => import('modules/zalo-mini-app/components/ui-management/UIManagement')));
const ZaloPromotionTagManagement = Loadable(lazy(() => import('modules/zalo-mini-app/components/tag-management/List')));
const TagDetail = Loadable(lazy(() => import('modules/zalo-mini-app/components/tag-management/TagDetail')));
const NewsList = Loadable(lazy(() => import('modules/zalo-mini-app/components/news/NewsList')));
const AddNews = Loadable(lazy(() => import('modules/zalo-mini-app/components/news/AddNews')));
const NewsDetail = Loadable(lazy(() => import('modules/zalo-mini-app/components/news/NewsDetail')));

const MarketingRoutes: RouteObject[] = [
  {
    path: 'quan-ly-san-pham',
    element: <ProductList />
  },
  {
    path: 'quan-ly-chatbot-fb',
    element: <FBChatbotResponseManagement />
  },
  {
    path: 'menu-generator',
    element: <BlogMenuGenerator />
  },
  {
    path: 'zalo-mini-app',
    children: [
      {
        index: true,
        element: <Navigate to={'promotion'} />
      },
      {
        path: 'promotion',
        element: <ZaloVoucherList />
      },
      {
        path: 'quan-ly-ui',
        element: <ZaloUIManagement />
      },
      {
        path: 'quan-ly-tag',
        children: [
          { index: true, element: <ZaloPromotionTagManagement /> },
          {
            path: ':id',
            element: <TagDetail />
          }
        ]
      },
      {
        path: 'tin-tuc',
        children: [
          {
            index: true,
            element: <NewsList />
          },
          {
            path: 'them',
            element: <AddNews />
          },
          {
            path: ':id',
            element: <NewsDetail />
          }
        ]
      }
    ]
  },
  {
    path: 'short-link',
    children: [
      {
        index: true,
        element: <ShortLinkPage />
      }
    ]
  },
  {
    path: 'auto-add-friend',
    element: <AutoAddFriend />
  }
];

export default MarketingRoutes;
