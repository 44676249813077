import { lazy } from 'react';
import GuestGuard from 'modules/auth/route-guard/GuestGuard';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import { RouteObject } from 'react-router';

const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
const ForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
const ResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));

const LoginRoutes: RouteObject = {
  path: '/',
  element: (
    <GuestGuard>
      <CommonLayout />
    </GuestGuard>
  ),
  children: [
    {
      path: 'dang-nhap',
      element: <AuthLogin />
    },
    {
      path: 'quen-mat-khau',
      element: <ForgotPassword />
    },
    {
      path: 'dat-lai-mat-khau',
      element: <ResetPassword />
    }
  ]
};

export default LoginRoutes;
