import { EException } from 'types/response-code/automation-py';

export const automationPyErrorMessageInfo: Record<EException, string> = {
  '-1': 'Vui lòng kiểm tra lại thông tin',
  '1': 'Lỗi bên thứ 3',
  '100': 'Không tìm thấy khách hàng',
  '101': 'Tài khoản Sapo đã có cài đặt add friend',
  '102': 'Lời chào quá dài',
  '2': 'Không tìm thấy dữ liệu'
};
