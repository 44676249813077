export const warehouseRoutes = {
  ecommerceOrder: {
    orders: '/kho-hang/qua-tang-tmdt',
    exportBatch: '/kho-hang/qua-tang-tmdt/dot-xuat-qua',
    goToExportBatchDetail: (id: number | string | undefined) => `/kho-hang/qua-tang-tmdt/dot-xuat-qua/${id}`,
    returnBatch: '/kho-hang/qua-tang-tmdt/dot-nhap-lai-qua',
    goToReturnBatchDetail: (id: number | string | undefined) => `/kho-hang/qua-tang-tmdt/dot-nhap-lai-qua/${id}`
  },
  delivery: {
    list: '/kho-hang/giao-hang',
    detail: '/kho-hang/giao-hang/:id',
    goToDetail: (id: string | number) => `/kho-hang/giao-hang/${id}`,
    create: '/kho-hang/giao-hang/them-moi',
    exportOrder: '/kho-hang/xuat-kho'
  },
  inventory: {
    list: '/kho-hang/kiem-ke',
    detail: '/kho-hang/kiem-ke/:id',
    goToDetail: (id: string | number) => `/kho-hang/kiem-ke/${id}`
  },
  orderNote: '/kho-hang/ghi-chu-don-hang',
  printOrder: {
    ecommerceOrder: '/kho-hang/in-don/don-tmdt',
    printBatch: {
      list: '/kho-hang/in-don',
      goToDetail: (id: number | string | undefined) => `/kho-hang/in-don/${id}`
    }
  }
};
