import {
  CalculatorOutlined,
  DeploymentUnitOutlined,
  InsertRowAboveOutlined,
  MobileOutlined,
  ShoppingOutlined,
  UserOutlined
} from '@ant-design/icons';
import { PERMISSIONS } from 'constants/permission';
import { ROUTE_URL } from 'constants/route';
import { NavItemType } from 'types/menu';

const report: NavItemType = {
  id: 'report',
  title: 'Báo cáo',
  type: 'collapse',
  icon: InsertRowAboveOutlined,
  children: [
    {
      id: 'hr',
      title: 'Nhân sự',
      type: 'collapse',
      icon: UserOutlined,
      children: [
        {
          id: 'timekeeping-on-leave',
          title: 'Chấm công & Nghỉ phép',
          type: 'item',
          url: ROUTE_URL.report.hr.timekeepingOnleave,
          permission: PERMISSIONS.timekeepingManage
        },
        {
          id: 'violation',
          title: 'Sai phạm',
          type: 'item',
          url: ROUTE_URL.report.hr.violationPoint.list,
          permission: PERMISSIONS.timekeepingManage
        }
      ]
    },
    {
      id: 'accounting',
      title: 'Kế toán',
      type: 'collapse',
      icon: CalculatorOutlined,
      children: [
        {
          id: 'shopee-debt',
          title: 'Công nợ Shopee',
          type: 'item',
          url: ROUTE_URL.report.accounting.shopeeDebt,
          permission: PERMISSIONS.accountingReport
        },
        {
          id: 'ghn-debt',
          title: 'Công nợ GHN',
          type: 'item',
          url: ROUTE_URL.report.accounting.ghnDebt,
          permission: PERMISSIONS.accountingReport
        },
        {
          id: 'tiktok-debt',
          title: 'Công nợ Tiktok',
          type: 'item',
          url: ROUTE_URL.report.accounting.tiktokDebt,
          permission: PERMISSIONS.accountingReport
        },
        {
          id: 'revenue-day-by-day',
          title: 'Doanh thu theo ngày',
          type: 'item',
          url: ROUTE_URL.report.accounting.revenueDayByDay,
          permission: PERMISSIONS.accountingReport
        }
      ]
    },
    {
      id: 'sale',
      title: 'Kinh Doanh',
      type: 'collapse',
      icon: ShoppingOutlined,
      children: [
        {
          id: 'store-revenue-by-day',
          title: 'Doanh số cửa hàng',
          type: 'item',
          url: ROUTE_URL.report.sale.inStoreReport,
          permission: PERMISSIONS.saleReport
        },
        {
          id: 'employee-revenue-by-month',
          title: 'Doanh số nhân viên',
          type: 'item',
          url: ROUTE_URL.report.sale.employeeRenenueReport,
          permission: PERMISSIONS.saleReport
        }
      ]
    },
    {
      id: 'marketing',
      title: 'Marketing',
      type: 'collapse',
      icon: DeploymentUnitOutlined,
      children: [
        {
          id: 'retail-report-by-chanel',
          title: 'Bán lẻ theo kênh',
          type: 'item',
          url: ROUTE_URL.report.marketing.retailByChanel,
          permission: PERMISSIONS.productManagement
        },
        {
          id: 'low-selling',
          title: 'SP bán không chạy',
          type: 'item',
          url: ROUTE_URL.report.marketing.lowSelling,
          permission: PERMISSIONS.productManagement
        },
        {
          id: 'selling',
          title: 'Tình hình bán hàng',
          type: 'item',
          url: ROUTE_URL.report.marketing.selling,
          permission: PERMISSIONS.productManagement
        },
        {
          id: 'profit-margin-rate-by-product',
          title: 'Tỷ suất lợi nhuận',
          type: 'item',
          url: ROUTE_URL.report.marketing.profitMarginRateByProduct,
          permission: PERMISSIONS.productManagement
        },
        {
          id: 'revenue-by-channel',
          title: 'Doanh thu theo kênh',
          type: 'item',
          url: ROUTE_URL.report.marketing.revenueByChannel,
          permission: PERMISSIONS.productManagement
        }
      ]
    },
    {
      id: 'zalo-mini-app',
      title: 'Zalo Mini App',
      type: 'collapse',
      icon: MobileOutlined,
      children: [
        {
          id: 'open-app-state',
          title: 'Tình hình mở app',
          type: 'item',
          url: ROUTE_URL.report.zaloMiniApp.openAppState,
          permission: PERMISSIONS.miniAppSettings
        },
        {
          id: 'point-state',
          title: 'Tình hình tích điểm',
          type: 'item',
          url: ROUTE_URL.report.zaloMiniApp.pointState,
          permission: PERMISSIONS.miniAppSettings
        },
        {
          id: 'user-state',
          title: 'Người dùng',
          type: 'item',
          url: ROUTE_URL.report.zaloMiniApp.user,
          permission: PERMISSIONS.miniAppSettings
        },
        {
          id: 'voucher-state',
          title: 'Quà đã đổi',
          type: 'item',
          url: ROUTE_URL.report.zaloMiniApp.voucher,
          permission: PERMISSIONS.miniAppSettings
        },
        {
          id: 'access-history-count',
          title: 'Lượt truy cập',
          type: 'item',
          url: ROUTE_URL.report.zaloMiniApp.accessHistoryCount,
          permission: PERMISSIONS.miniAppSettings
        },
        {
          id: 'recent-interact',
          title: 'Tương tác gần đây',
          type: 'item',
          url: ROUTE_URL.report.zaloMiniApp.recentInteract,
          permission: PERMISSIONS.miniAppSettings
        }
      ]
    }
  ]
};

export default report;
