export const systemRoutes = {
  tiktokAuth: '/system/tiktok-auth',
  crawlData: '/system/crawl-data',
  fetchScheduler: '/system/fetch-scheduler',
  cronJob: {
    jira: '/system/cron-job/jira',
    running: '/system/cron-job/running'
  },
  location: '/system/chi-nhanh',
  resource: '/system/resource',
  service: '/system/service',
  role: {
    list: '/system/vai-tro',
    create: '/system/vai-tro/them-moi',
    goToDetail: (id: number | string) => `/system/vai-tro/${id}`
  },
  jiraTimelog: {
    dashboard: '/system/jira-time-log',
    goToLogByUser: (username: string) => `/system/jira-time-log/${username}`
  },
  errorLogger: '/system/error-logger'
};
