import Loadable from 'components/Loadable';
import { lazy } from 'react';
import { RouteObject } from 'react-router';

const ProfilePage = Loadable(lazy(() => import('pages/account/profile')));
const ChangePasswordPage = Loadable(lazy(() => import('pages/account/change-password')));
const MyJournalEntryPage = Loadable(lazy(() => import('modules/employee-journal-entry/components/my-journal-entry/MyJournalEntryPage')));
const MySalary = Loadable(lazy(() => import('modules/salary/components/MySalary')));

const MeRoutes: RouteObject[] = [
  {
    index: true,
    element: <ProfilePage />
  },
  {
    path: 'doi-mat-khau',
    element: <ChangePasswordPage />
  }
  // {
  //   path: 'bang-luong',
  //   element: <MySalary />
  // },
  // {
  //   path: 'cong-no',
  //   element: <MyJournalEntryPage />
  // }
];

export default MeRoutes;
