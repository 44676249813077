import { AlertOutlined, ShopOutlined } from '@ant-design/icons';
import { PERMISSIONS } from 'constants/permission';
import { ROUTE_URL } from 'constants/route';
import { NavItemType } from 'types/menu';

const saleManagement: NavItemType = {
  id: 'sale',
  title: 'Kinh doanh',
  type: 'collapse',
  icon: ShopOutlined,
  children: [
    {
      id: 'complaint',
      title: 'Khiếu nại',
      type: 'item',
      icon: AlertOutlined,
      url: ROUTE_URL.sale.complaint.list,
      permission: PERMISSIONS.customerComplaint
    }
  ]
};

export default saleManagement;
