export const reportRoutes = {
  sale: {
    inStoreReport: '/bao-cao/kinh-doanh/doanh-so-cua-hang',
    employeeRenenueReport: '/bao-cao/kinh-doanh/doanh-so-nhan-vien'
  },
  accounting: {
    shopeeDebt: '/bao-cao/ke-toan/cong-no-shopee',
    ghnDebt: '/bao-cao/ke-toan/cong-no-ghn',
    tiktokDebt: '/bao-cao/ke-toan/cong-no-tiktok',
    revenueDayByDay: '/bao-cao/ke-toan/doanh-thu-theo-ngay'
  },
  marketing: {
    retailByChanel: '/bao-cao/marketing/ban-le-theo-kenh',
    lowSelling: '/bao-cao/marketing/san-pham-ban-khong-chay',
    selling: '/bao-cao/marketing/tinh-hinh-ban-hang',
    profitMarginRateByProduct: '/bao-cao/marketing/ty-suat-loi-nhuan-theo-san-pham',
    revenueByChannel: '/bao-cao/marketing/doanh-thu-theo-kenh'
  },
  zaloMiniApp: {
    openAppState: '/bao-cao/zalo-mini-app/so-lan-mo-app',
    pointState: '/bao-cao/zalo-mini-app/phat-sinh-diem',
    user: '/bao-cao/zalo-mini-app/nguoi-dung',
    voucher: '/bao-cao/zalo-mini-app/qua-da-doi',
    accessHistoryCount: '/bao-cao/zalo-mini-app/luot-truy-cap',
    recentInteract: '/bao-cao/zalo-mini-app/tuong-tac-gan-day'
  },
  hr: {
    timekeepingOnleave: '/bao-cao/nhan-su/cham-cong-nghi-phep',
    violationPoint: {
      list: '/bao-cao/nhan-su/diem-sai-pham',
      goToDetail: (id: number | string) => `/bao-cao/nhan-su/diem-sai-pham/${id}`
    }
  }
};
