import { EException } from 'types/response-code/chot-ca';

export const chotCaErrorMessageInfo: Record<EException, string> = {
  '9': 'Không tìm thấy dữ liệu',
  '4': 'Lỗi từ hệ thống Sapogo',
  '7': 'Có tiến trình đang được xử lý, vui lòng thử lại sau',
  '1100': 'Đơn hàng đã được thêm quà',
  '1101': 'Số lượng quà không đủ',
  '1102': 'Đơn hàng này đã được xuất quà tặng',
  '1103': 'Đợt xuất quà đã được xuất kho',
  '1104': 'Không có đơn hàng nào cần nhập quà lại',
  '1105': 'Đợt xuất quà đã bị hủy bỏ',
  '-1': 'Vui lòng kiểm tra lại thông tin'
};
