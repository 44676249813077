import Loadable from 'components/Loadable';
import { lazy } from 'react';
import { RouteObject } from 'react-router';
const ComplaintList = Loadable(lazy(() => import('modules/complaint/components/List')));
const ComplaintDetail = Loadable(lazy(() => import('modules/complaint/components/Detail')));

const SaleRoutes: RouteObject[] = [
  {
    path: 'khieu-nai',
    children: [
      {
        index: true,
        element: <ComplaintList />
      },
      {
        path: ':id',
        element: <ComplaintDetail />
      }
    ]
  }
];

export default SaleRoutes;
