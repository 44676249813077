import Loadable from 'components/Loadable';
import { lazy } from 'react';
import { RouteObject } from 'react-router';
const CashBookList = Loadable(lazy(() => import('modules/accounting/components/cash-book/CashBookList')));

const AccountingRoutes: RouteObject[] = [
  {
    path: 'so-quy',
    element: <CashBookList />
  }
];

export default AccountingRoutes;
