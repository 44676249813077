import Loadable from 'components/Loadable';
import { lazy } from 'react';
import { RouteObject } from 'react-router';
const TiktokAuthList = Loadable(lazy(() => import('modules/tiktok-auth/components/List')));
const CrawlManagement = Loadable(lazy(() => import('modules/crawl-data/components/CrawlManagement')));
// const CronJobListPage = Loadable(lazy(() => import('modules/cron-job/components/CronJobListPage')));
const JiraIssueCronJobListPage = Loadable(lazy(() => import('modules/cron-job/components/JiraIssueCronJobListPage')));
const ResourceListPage = Loadable(lazy(() => import('pages/resource/list')));
const RoleListPage = Loadable(lazy(() => import('pages/role/list')));
const CreateRolePage = Loadable(lazy(() => import('pages/role/create')));
const RoleDetailPage = Loadable(lazy(() => import('pages/role/detail')));
const LocationListPage = Loadable(lazy(() => import('pages/location/list')));
const ServiceListPage = Loadable(lazy(() => import('modules/service/component/ServiceListPage')));
const RunningCronJobPage = Loadable(lazy(() => import('modules/cron-job/components/RunningCronJobPage')));
const JiraTimeLog = Loadable(lazy(() => import('modules/jira-time-log/components/JiraTimeLog')));
const JiraTimeLogByUser = Loadable(lazy(() => import('modules/jira-time-log/components/JiraTimeLogByUser')));
const ErrorLogListPage = Loadable(lazy(() => import('modules/error-logging/components/List')));

const SystemRoutes: RouteObject[] = [
  {
    path: 'tiktok-auth',
    element: <TiktokAuthList />
  },
  {
    path: 'crawl-data',
    element: <CrawlManagement />
  },
  {
    path: 'cron-job',
    children: [
      {
        path: 'jira',
        element: <JiraIssueCronJobListPage />
      },
      {
        path: 'running',
        element: <RunningCronJobPage />
      }
    ]
  },
  {
    path: 'resource',
    element: <ResourceListPage />
  },
  {
    path: 'service',
    element: <ServiceListPage />
  },
  {
    path: 'vai-tro',
    children: [
      {
        index: true,
        element: <RoleListPage />
      },
      {
        path: 'them-moi',
        element: <CreateRolePage />
      },
      {
        path: ':id',
        element: <RoleDetailPage />
      }
    ]
  },
  {
    path: 'chi-nhanh',
    element: <LocationListPage />
  },
  {
    path: 'jira-time-log',
    children: [
      {
        index: true,
        element: <JiraTimeLog />
      },
      {
        path: ':username',
        element: <JiraTimeLogByUser />
      }
    ]
  },
  {
    path: 'error-logger',
    element: <ErrorLogListPage />
  }
];

export default SystemRoutes;
