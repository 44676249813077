import axios, { AxiosError, AxiosInstance } from 'axios';
import { getAccessToken } from './local-storage';
import { ServiceRoute } from 'constants/api';
import { ENV } from 'constants/env';
import auth from 'api/new-auth';

let refreshTokenPromise: any;

const handleRenewToken = () => {
  refreshTokenPromise = auth.renewAccessToken();

  return refreshTokenPromise as Promise<void>;
};

export default class Axios {
  instance: AxiosInstance;

  constructor(serviceRoute: ServiceRoute) {
    const newAxios = axios.create({
      baseURL: `${ENV.apiUrl}/${serviceRoute}`,
      timeout: 10000
    });

    newAxios.interceptors.request.use(function (config) {
      const accessToken = getAccessToken();
      config.headers.token = accessToken;
      return config;
    });

    newAxios.interceptors.response.use(
      function (response) {
        return response;
      },
      async function (error: AxiosError) {
        if (error.config && error.response && error.response.status === 401) {
          if ((error.response.data as any)?.code !== 2) {
            return Promise.reject({
              error,
              serviceRoute
            });
          }

          if (!refreshTokenPromise) {
            return handleRenewToken().then(() => {
              refreshTokenPromise = null;
              return newAxios.request(error.config!);
            });
          }

          await refreshTokenPromise;

          return newAxios.request(error.config);
        }

        return Promise.reject({
          error,
          serviceRoute
        });
      }
    );

    this.instance = newAxios;
  }
}

export class GeneralAxios extends Axios {
  constructor(serviceRoute: ServiceRoute) {
    super(serviceRoute);
    this.instance.interceptors.response.use((r) => r.data || r);
  }
}

export class DownloadAxios extends Axios {
  constructor(serviceRoute: ServiceRoute) {
    super(serviceRoute);
    this.instance.interceptors.request.use(function (config) {
      config.responseType = 'arraybuffer';
      return config;
    });
  }
}
