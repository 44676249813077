import { BookOutlined, CalculatorOutlined } from '@ant-design/icons';
import { PERMISSIONS } from 'constants/permission';
import { ROUTE_URL } from 'constants/route';
import { NavItemType } from 'types/menu';

const accountingManagement: NavItemType = {
  id: 'accounting',
  title: 'Kế toán',
  type: 'collapse',
  icon: CalculatorOutlined,
  children: [
    {
      id: 'cash-book',
      title: 'Sổ quỹ',
      type: 'item',
      icon: BookOutlined,
      url: ROUTE_URL.accounting.cashbook,
      permission: PERMISSIONS.accountingReport
    }
  ]
};

export default accountingManagement;
