import Loadable from 'components/Loadable';
import { lazy } from 'react';
import { RouteObject } from 'react-router';

// sale
const InStoreSalesReport = Loadable(lazy(() => import('modules/sale/components/in-store-report/InStoreReport')));
const EmployeeRevenueReport = Loadable(lazy(() => import('modules/sale/components/EmployeeRevenue')));

// accounting
const ShopeeDebtReport = Loadable(lazy(() => import('modules/accounting-report/components/shopee-debt/Report')));
const GHNDebtReport = Loadable(lazy(() => import('modules/accounting-report/components/ghn-debt/Report')));
const TiktokDebtReport = Loadable(lazy(() => import('modules/accounting-report/components/tiktok-debt/Report')));
const RevenueDayByDay = Loadable(lazy(() => import('modules/accounting-report/components/revenue-day-by-day/RevenueDayByDay')));

// marketing
// const ExportReport = Loadable(lazy(() => import('modules/mkt-report/components/export-report/ExportReport')));
const ProfitMarginRateReport = Loadable(lazy(() => import('modules/mkt-report/components/profit-margin-rate/Report')));
const RevenueByChannelReport = Loadable(lazy(() => import('modules/mkt-report/components/revenue-by-channel/Report')));
const RetailReportByChanel = Loadable(lazy(() => import('modules/mkt-report/components/retail-report-by-chanel/Report')));
const LowSellingReport = Loadable(lazy(() => import('modules/mkt-report/components/low-selling/Report')));
const SixMonthSellingReport = Loadable(lazy(() => import('modules/mkt-report/components/6-month-selling')));

// mini app
const OpenAppReport = Loadable(lazy(() => import('modules/zalo-mini-app/components/report/OpenAppReport')));
const PointReport = Loadable(lazy(() => import('modules/zalo-mini-app/components/report/PointStateReport')));
const UserReport = Loadable(lazy(() => import('modules/zalo-mini-app/components/report/UserReport')));
const VoucherReport = Loadable(lazy(() => import('modules/zalo-mini-app/components/report/VoucherReport')));
const AccessCountHistoryReport = Loadable(
  lazy(() => import('modules/zalo-mini-app/components/report/access-history-count/AccessHistoryCountReport'))
);
const RecentInteractReport = Loadable(lazy(() => import('modules/zalo-mini-app/components/report/RecentInteract')));

// hr
const TimekeepingReport = Loadable(lazy(() => import('modules/timekeeping/components/Report')));
const ViolationPointReport = Loadable(lazy(() => import('modules/violation/components/ViolationReport')));
const ViolationPointDetailPage = Loadable(lazy(() => import('modules/violation/components/ViolationPointDetailPage')));

const ReportRoutes: RouteObject[] = [
  {
    path: 'kinh-doanh',
    children: [
      {
        path: 'doanh-so-cua-hang',
        element: <InStoreSalesReport />
      },
      {
        path: 'doanh-so-nhan-vien',
        element: <EmployeeRevenueReport />
      }
    ]
  },
  {
    path: 'ke-toan',
    children: [
      {
        path: 'cong-no-shopee',
        element: <ShopeeDebtReport />
      },
      {
        path: 'cong-no-ghn',
        element: <GHNDebtReport />
      },
      {
        path: 'cong-no-tiktok',
        element: <TiktokDebtReport />
      },
      {
        path: 'doanh-thu-theo-ngay',
        element: <RevenueDayByDay />
      }
    ]
  },
  {
    path: 'marketing',
    children: [
      {
        path: 'ban-le-theo-kenh',
        element: <RetailReportByChanel />
      },
      {
        path: 'san-pham-ban-khong-chay',
        element: <LowSellingReport />
      },
      {
        path: 'tinh-hinh-ban-hang',
        element: <SixMonthSellingReport />
      },
      {
        path: 'ty-suat-loi-nhuan-theo-san-pham',
        element: <ProfitMarginRateReport />
      },
      {
        path: 'doanh-thu-theo-kenh',
        element: <RevenueByChannelReport />
      }
      // {
      //   path: 'export',
      //   element: <ExportReport />
      // }
    ]
  },
  {
    path: 'zalo-mini-app',
    children: [
      {
        path: 'so-lan-mo-app',
        element: <OpenAppReport />
      },
      {
        path: 'phat-sinh-diem',
        element: <PointReport />
      },
      {
        path: 'nguoi-dung',
        element: <UserReport />
      },
      {
        path: 'qua-da-doi',
        element: <VoucherReport />
      },
      {
        path: 'luot-truy-cap',
        element: <AccessCountHistoryReport />
      },
      {
        path: 'tuong-tac-gan-day',
        element: <RecentInteractReport />
      }
    ]
  },
  {
    path: 'nhan-su',
    children: [
      {
        path: 'cham-cong-nghi-phep',
        element: <TimekeepingReport />
      },
      {
        path: 'diem-sai-pham',
        children: [
          {
            index: true,
            element: <ViolationPointReport />
          },
          {
            path: ':id',
            element: <ViolationPointDetailPage />
          }
        ]
      }
    ]
  }
];

export default ReportRoutes;
