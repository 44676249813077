import { EException } from 'types/response-code/cron-job';

export const cronJobErrorMessageInfo: Record<EException, string> = {
  '-1': 'Vui lòng kiểm tra lại thông tin',
  '10': 'Không thể chỉnh sửa dữ liệu này',
  '100': 'Loại issue này không khả dùng cho subtask',
  '101': 'Loại issue này không khả dùng cho task',
  '11': 'Bạn không phải là người tạo',
  '2': 'File upload không hợp lệ',
  '3': 'Số điện thoại không hợp lệ',
  '4': 'Dữ liệu không tìm thấy',
  '5': 'Đang xử lý',
  '6': 'Dữ liệu đã tồn tại',
  '7': 'Dữ liệu không tìm thấy',
  '8': 'Bạn không phải là người tạo',
  '9': 'Lỗi dịch vụ thứ 3'
};
