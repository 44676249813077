import {
  DropboxOutlined,
  FileDoneOutlined,
  GiftOutlined,
  GoldOutlined,
  PrinterOutlined,
  SnippetsOutlined,
  TruckOutlined
} from '@ant-design/icons';
import { PERMISSIONS } from 'constants/permission';
import { ROUTE_URL } from 'constants/route';
import { NavItemType } from 'types/menu';

const wareHouse: NavItemType = {
  id: 'ware-house',
  title: 'Kho hàng',
  type: 'collapse',
  icon: GoldOutlined,
  children: [
    {
      id: 'print-order',
      title: 'In đơn hàng',
      type: 'collapse',
      icon: PrinterOutlined,
      children: [
        {
          id: 'print-batch',
          title: 'Đợt in đơn hàng',
          type: 'item',
          url: ROUTE_URL.warehouse.printOrder.printBatch.list,
          permission: PERMISSIONS.ecommerceOrderPrint
        },
        {
          id: 'order',
          title: 'In đơn hàng',
          type: 'item',
          url: ROUTE_URL.warehouse.printOrder.ecommerceOrder,
          permission: PERMISSIONS.ecommerceOrderPrint
        }
      ]
    },
    {
      id: 'delivery',
      title: 'Bàn giao vận đơn',
      type: 'item',
      icon: TruckOutlined,
      url: ROUTE_URL.warehouse.delivery.list,
      permission: PERMISSIONS.packing
    },
    {
      id: 'export',
      title: 'Xuất kho',
      type: 'item',
      icon: DropboxOutlined,
      url: ROUTE_URL.warehouse.delivery.exportOrder,
      permission: PERMISSIONS.packing
    },
    {
      id: 'order-note',
      title: 'Ghi chú đơn hàng',
      type: 'item',
      icon: SnippetsOutlined,
      url: ROUTE_URL.warehouse.orderNote,
      permission: PERMISSIONS.orderNote
    },
    {
      id: 'e-commerce-order-gift',
      title: 'Quà tặng đơn TMĐT',
      type: 'collapse',
      icon: GiftOutlined,
      children: [
        {
          id: 'order',
          title: 'Đơn hàng TMĐT',
          type: 'item',
          url: ROUTE_URL.warehouse.ecommerceOrder.orders
        },
        {
          id: 'export-gift',
          title: 'Xuất quà tặng',
          type: 'item',
          url: ROUTE_URL.warehouse.ecommerceOrder.exportBatch
        },
        {
          id: 'return-gift',
          title: 'Nhập lại quà',
          type: 'item',
          url: ROUTE_URL.warehouse.ecommerceOrder.returnBatch
        }
      ]
    },
    {
      id: 'inventory',
      title: 'Kiểm kê',
      type: 'item',
      icon: FileDoneOutlined,
      url: ROUTE_URL.warehouse.inventory.list,
      permission: PERMISSIONS.inventory
    }
  ]
};

export default wareHouse;
