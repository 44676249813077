import Loadable from 'components/Loadable';
import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router';

const EmployeesListPage = Loadable(lazy(() => import('pages/employees/list')));
const AddEmployeePage = Loadable(lazy(() => import('pages/employees/create')));
const EmployeeDetailPage = Loadable(lazy(() => import('pages/employees/detail')));
const DepartmentListPage = Loadable(lazy(() => import('pages/department/list')));
const PositionListPage = Loadable(lazy(() => import('pages/position/list')));
const TimekeepingHistoryList = Loadable(lazy(() => import('modules/timekeeping/components/TimekeepingHistoryList')));
const OnLeaveListPage = Loadable(lazy(() => import('modules/on-leave/components/OnLeaveListPage')));
const OnLeaveTicketDetailPage = Loadable(lazy(() => import('modules/on-leave/components/OnLeaveTicketDetailPage')));
const TimekeepingTicketListPage = Loadable(lazy(() => import('modules/timekeeping/components/TimekeepingTicketsList')));
const TimekeepingTicketDetailPage = Loadable(lazy(() => import('modules/timekeeping/components/TimekeepingTicketDetailPage')));
const SystemTimekeepingTicketsList = Loadable(lazy(() => import('modules/timekeeping/components/SystemTimekeepingTicketsList')));
const SystemTimekeepingTicketDetailPage = Loadable(lazy(() => import('modules/timekeeping/components/SystemTimekeepingTicketDetailPage')));
const ViolationListPage = Loadable(lazy(() => import('modules/violation/components/ViolationListPage')));
const ViolationDetailPage = Loadable(lazy(() => import('modules/violation/components/ViolationDetailPage')));
const CreateViolation = Loadable(lazy(() => import('modules/violation/components/CreateViolation')));
const EmployeeJournalEntryPage = Loadable(
  lazy(() => import('modules/employee-journal-entry/components/employee-journal-entry/EmployeeJournalEntryPage'))
);
const CreateDealPeriodPage = Loadable(lazy(() => import('modules/salary/components/CreateDealPeriodPage')));
const DealPeriodDetailPage = Loadable(lazy(() => import('modules/salary/components/DealPeriodDetailPage')));
const DealPeriodListPage = Loadable(lazy(() => import('modules/salary/components/DealPeriodListPage')));
const EmployeeSalary = Loadable(lazy(() => import('modules/salary/components/EmployeeSalary')));
const ShiftLeaderManagement = Loadable(lazy(() => import('modules/salary/components/shift-leader/ShiftLeaderManagement')));

const HrRoutes: RouteObject[] = [
  {
    index: true,
    element: <Navigate to={'nhan-vien'} />
  },
  {
    path: 'nhan-vien',
    children: [
      {
        index: true,
        element: <EmployeesListPage />
      },
      {
        path: ':id',
        element: <EmployeeDetailPage />
      },
      {
        path: 'tao-moi',
        element: <AddEmployeePage />
      }
    ]
  },

  {
    path: 'lich-su-cham-cong',
    element: <TimekeepingHistoryList />
  },
  {
    path: 'phieu-nghi-phep',
    children: [
      {
        index: true,
        element: <OnLeaveListPage />
      },
      {
        path: ':id',
        element: <OnLeaveTicketDetailPage />
      }
    ]
  },
  {
    path: 'phieu-cham-cong',
    children: [
      {
        index: true,
        element: <TimekeepingTicketListPage />
      },
      {
        path: ':id',
        element: <TimekeepingTicketDetailPage />
      }
    ]
  },
  {
    path: 'cham-cong-he-thong',
    children: [
      {
        index: true,
        element: <SystemTimekeepingTicketsList />
      },
      {
        path: ':id',
        element: <SystemTimekeepingTicketDetailPage />
      }
    ]
  },
  {
    path: 'phieu-sai-pham',
    children: [
      {
        index: true,
        element: <ViolationListPage />
      },
      {
        path: 'tao-sai-pham',
        element: <CreateViolation />
      },
      {
        path: ':id',
        element: <ViolationDetailPage />
      }
    ]
  },
  {
    path: 'phong-ban',
    element: <DepartmentListPage />
  },
  {
    path: 'chuc-vu',
    element: <PositionListPage />
  },
  {
    path: 'thoa-thuan-luong',
    children: [
      {
        index: true,
        element: <DealPeriodListPage />
      },
      {
        path: 'them-moi',
        element: <CreateDealPeriodPage />
      },
      {
        path: ':id',
        element: <DealPeriodDetailPage />
      }
    ]
  },
  {
    path: 'bang-luong',
    element: <EmployeeSalary />
  },
  {
    path: 'cong-no-nhan-vien',
    element: <EmployeeJournalEntryPage />
  },
  {
    path: 'truong-ca',
    element: <ShiftLeaderManagement />
  }
];

export default HrRoutes;
