import Loadable from 'components/Loadable';
import { lazy } from 'react';
import { RouteObject } from 'react-router';

// qua tang don hang tmdt
const EcommerceOrders = Loadable(lazy(() => import('modules/e-commerce-gift/components/EcommerceOrderList')));
const EcommerceExportGiftBatchList = Loadable(lazy(() => import('modules/e-commerce-gift/components/export-batch/ExportGiftBatchList')));
const EcommerceExportGiftBatchDetail = Loadable(
  lazy(() => import('modules/e-commerce-gift/components/export-batch/ExportGiftBatchDetail'))
);
const ReturnBatchesPage = Loadable(lazy(() => import('modules/e-commerce-gift/components/return-batches/ReturnBatchesPage')));
const ReturnBatchDetailPage = Loadable(lazy(() => import('modules/e-commerce-gift/components/return-batches/ReturnBatchDetailPage')));

// xuat kho
const DeliveryDetailPage = Loadable(lazy(() => import('modules/delivery/components/detail/DeliveryDetailPage')));
const InventoryListPage = Loadable(lazy(() => import('modules/inventory/components/list/ListPage')));
const InventoryDetailPage = Loadable(lazy(() => import('modules/inventory/components/detail/InventoryDetailPage')));
const ExportOrderList = Loadable(lazy(() => import('modules/delivery/components/export-order/ExportOrderList')));
const DeliveryList = Loadable(lazy(() => import('modules/delivery/components/list/DeliveryList')));

// ghi chu don hang tmdt
const OrderNoteList = Loadable(lazy(() => import('modules/order-note/components/List')));

// in don hang tmdt
const OrderList = Loadable(lazy(() => import('modules/print-order/components/orders/OrderList')));
const PrintBatchDetail = Loadable(lazy(() => import('modules/print-order/components/print-batch/PrintBatchDetail')));
const PrintBatchList = Loadable(lazy(() => import('modules/print-order/components/print-batch/PrintBatchList')));

const WarehouseRoutes: RouteObject[] = [
  {
    path: 'qua-tang-tmdt',
    children: [
      {
        index: true,
        element: <EcommerceOrders />
      },
      {
        path: 'dot-xuat-qua',
        children: [
          {
            index: true,
            element: <EcommerceExportGiftBatchList />
          },
          {
            path: ':id',
            element: <EcommerceExportGiftBatchDetail />
          }
        ]
      },
      {
        path: 'dot-nhap-lai-qua',
        children: [
          {
            index: true,
            element: <ReturnBatchesPage />
          },
          {
            path: ':id',
            element: <ReturnBatchDetailPage />
          }
        ]
      }
    ]
  },
  {
    path: 'giao-hang',
    children: [
      {
        index: true,
        element: <DeliveryList />
      },
      {
        path: ':id',
        element: <DeliveryDetailPage />
      }
    ]
  },
  {
    path: 'xuat-kho',
    element: <ExportOrderList />
  },
  {
    path: 'kiem-ke',
    children: [
      {
        index: true,
        element: <InventoryListPage />
      },
      {
        path: ':id',
        element: <InventoryDetailPage />
      }
    ]
  },
  {
    path: 'ghi-chu-don-hang',
    element: <OrderNoteList />
  },
  {
    path: 'in-don',
    children: [
      {
        index: true,
        element: <PrintBatchList />
      },
      {
        path: 'don-tmdt',
        element: <OrderList />
      },
      {
        path: ':id',
        element: <PrintBatchDetail />
      }
    ]
  }
];

export default WarehouseRoutes;
