import MainLayout from 'layout/MainLayout';
import { Navigate, RouteObject } from 'react-router';
import AuthGuard from 'modules/auth/route-guard/AuthGuard';
import MeRoutes from './me';
import HrRoutes from './hr';
import SaleRoutes from './sale';
import MarketingRoutes from './marketing';
import AccountingRoutes from './accounting';
import WarehouseRoutes from './warehouse';
import SystemRoutes from './system';
import ReportRoutes from './report';

const MainRoutes: RouteObject = {
  path: '',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      index: true,
      element: <Navigate to="me" />
    },
    {
      path: 'me',
      children: MeRoutes
    },
    {
      path: 'nhan-su',
      children: HrRoutes
    },
    {
      path: 'kinh-doanh',
      children: SaleRoutes
    },
    {
      path: 'marketing',
      children: MarketingRoutes
    },
    {
      path: 'ke-toan',
      children: AccountingRoutes
    },
    {
      path: 'kho-hang',
      children: WarehouseRoutes
    },
    {
      path: 'system',
      children: SystemRoutes
    },
    {
      path: 'bao-cao',
      children: ReportRoutes
    }
  ]
};

export default MainRoutes;
